.meet__our__team__main{
    width: 100%;
    padding: 50px 0;
    background-color: var(--blue);

    .flex__section{
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .content__section{
            width: 40%;

            h5{
                font-size: 22px;
                text-transform: uppercase;
                color: var(--snowflake);
                
            }

            p{
                font-size: 44px;
                color: #fff;
                font-weight: 700;
                line-height: 1;
                margin-top: 20px;

                &.description{
                    font-weight: 500;
                    font-size: 22px;
                    margin-top: 25px;
                    max-width: 60%;
                    span{
                        color: var(--snowflake);
                        line-height: 1.2;
                    }
                }
            }
        }
        .image__section{
            width: 60%;
            display: flex;
            justify-content: flex-end;
            
            img{
                max-width: 100%;
                border-radius: 10px;
                // margin-right: -30%;
            }
        }
    }
}

@media only screen and (max-width:980px){
    .meet__our__team__main{
        .flex__section{
            .content__section{
                width: 55%;

            }

            .image__section{
                width: 45%;
                img{
                    max-width: 100%;
                }
            }
        }
    }   
}

@media only screen and (max-width:768px){
    .meet__our__team__main{
        .flex__section{
            .content__section{
                width: 100%;
                text-align: center;
                h5{
                    font-size: 18px;
                }

                p{
                    font-size: 30px;
                    margin-top: 15px;
                    max-width: 80%;
                    margin: auto;
                    &.description{
                        font-size: 18px;
                        margin-top: 20px;
                        max-width: 80%;
                    }
                }

            }

            .image__section{
                width: 100%;
                justify-content: center;
                img{
                    max-width: 60%;
                    margin-right: 0;
                }
            }
        }
    }   
}
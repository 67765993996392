.success__stories__main{
    width: 100%;
    padding: 100px 0;
    background-image: url("../../../assets/images/successbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--blue);

    h2{
        font-size: 58px;
        font-weight: 500;
        max-width: 600px;
        text-align: center;
        line-height: 1;
        span{
            font-weight: 800;
        }
    }
    p{
        font-size: 24px;
        font-weight: 400;
        margin-top: 15px;
        text-align: center;

    }
}

@media only screen and (max-width:525px){
    .success__stories__main{
        h2{
            font-size: 28px;
        }
        p{
            font-size: 14px;
        }
    }
}
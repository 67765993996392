@import "bootstrap/dist/css/bootstrap.min.css";
@import url('./src/theme/fonts/gilroy/stylesheet.css');
@import url('./src/theme/fonts/Nunito/stylesheet.css');
@import url("./src/theme/fonts/texta/stylesheet.css");

:root {
  --primary-color: #042e80;
  --primary-font:Nunito;
  --green:#50c2bb;
  --blue:#182a3b;
  --gray:rgb(102, 102, 102);
  --snowflake:#249EDC;
  --init-font:Texta;
}

*{
  font-family: var(--primary-font), Arial, sans-serif;
}
body{
  overflow-x: hidden;
}

p,h1,h2,h3,h4,h5,h6{
  margin-bottom: 0 !important;
}


.custom__container {
  width: 100%;
  padding: 0 20px;

  @media (min-width:576px) {
    max-width: 100%;
    padding: 0 40px;
  }

  @media (min-width:992px) {
    max-width: 960px;
    margin: auto;
    padding: 0;
  }

  @media (min-width:1200px) {
    max-width: 1160px;
  }

  @media (min-width:1300px) {
    max-width: 1240px;
  }

  @media (min-width:1400px) {
    max-width: 1234px;
  }

  @media (min-width:1400px) {
    max-width: 1320px;
  }
}
.outline__filled__btn{
    padding: 8px 16px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 14px;
    font-family: var(--init-font);
    text-transform: uppercase;
    &.outline__btn{
        border:1px solid #249edc;
        color: #249edc !important;
        background-color: transparent;
        transition: all .3s ease-in-out;
        &:hover{
            color: #11567f !important;
            border-color: #11567f;
        }
    }
    &.filled__btn{
        border: none;
        background-color: #249edc;
        color: white; 
        transition: all .3s ease-in-out;
        &:hover{
            background-color: #11567f;
        }
    }
}
.workload__container{
    width: 100%;
    background-color: #F6F9FA;
    padding: 60px 0;

    .header__section{
        h3{
            font-size: 44px;
            font-weight: 700;
            color: var(--snowflake);
            text-align: center;
            span{
                color: var(--blue);
            }
        }

        p{
            font-size: 18px;
            font-weight: 500;
            color: var(--gray);
            text-align: center;
            max-width: 800px;
            margin: auto;
            margin-top: 20px;
        }
    }
 
    .flex__section{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 25px;

        .cards__section{
            width: 50%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            row-gap: 20px;

        }

        .image__section{
            width: 50%;
            text-align: right;
            
            img{
                max-width: 90%;
            }
        }
    }
}

@media only screen and (max-width:980px){
    .workload__container{
        .flex__section{
            .cards__section{
                width: 100%;
                justify-content: space-around;
            }

            .image__section{
                width: 100%;
                text-align: center;
                margin-top: 20px;
                img{
                    max-width: 60%;
                }
            }
        }
    }
}
.link__btn__main {
    text-decoration: none;
    color: var(--gray);
    font-size: 17px;
    font-weight: 500;
    transform: translateX(-10px);
    display: block;
    transition: all 0.3s ease-in-out;

    &:hover {
        color: rgb(46, 163, 242);
        transform: translateX(0);
    }
}
.swiper {
  width: 100%;
}

.swiper-slide {
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonial__carousel {

  .swiper-wrapper {
    padding-bottom: 40px;

    .swiper-slide {
      .testimonial__card {
        background-color: #F6F3FE;
        padding: 40px;
        border-radius: 10px;
        height: 100%;
        width: 100%;
        padding-left: 80px;

        .comma__icon{
          position: absolute;
          right: 40px;
          top: 40px;
          color: #182A3B;
        }

        p {
          text-align: left;
          color: var(--gray);
          font-size: 18px;
          font-weight: 500;
        }

        .reviewer {
          // display: flex;
          // gap: 20px;
          // align-items: center;
          // margin-top: 20px;

          p {
            color: var(--blue);
            font-size: 20px;
            font-weight: 700;
          }

          h4 {
            color: var(--gray);
            font-size: 16px;
            font-weight: 600;
          }

          img {
            width: 80px;
            height: 80px;
          }
        }
      }
    }

  }
}

.custom__pagination {
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  .bullet__container {
    width: 15px;
    height: 15px;
    border: 1px solid transparent;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      width: 6px;
      height: 6px;
      background-color: var(--blue);
      border-radius: 100%;
    }

  }

  .active__bullet {
    width: 15px;
    height: 15px;
    border: 1px solid var(--blue);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      width: 6px;
      height: 6px;
      background-color: var(--blue);
      border-radius: 100%;
    }
  }
}


@media only screen and (max-width:980px){
  .testimonial__carousel{
      .swiper-wrapper{
        .swiper-slide{
          .testimonial__card{
            display: flex;
            flex-direction: column;
            align-items: center;

            p{
              text-align: center;
            }
            .reviewer{
              p{
                text-align: left;
              }
            }
          }
        }
      }
  }
  .custom__pagination{
    justify-content: center;
  }
}
.language__dropdown {
  display: block;
    .dropdown__toggle {
      font-size: 14px;
    }
  
    .dropdown__menu__custom {
      min-width: 150px;
      padding: 10px;
      background-color: white;
      border: none;
      border-radius: 6px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
      .dropdown__title {
        font-size: 14px;
        font-weight: 600;
        color: #249edc; 
        margin-bottom: 10px;
        text-transform: uppercase;
        padding: 20px;
      }
  
      .dropdown__item__hover {
        font-size: 14px;
        padding: 5px 10px;
        border-radius: 4px;
        color: #000;
  
        &:hover {
          background-color: transparent;
          color: #249edc;
        }
  
      }
    }
    
  }
  .mobile__language__dropDown{
    display: none ;
  }
  
  @media only screen and (max-width:1024px) {
    .language__dropdown{
      display: none;
    }
    .mobile__language__dropDown{
      display: block ;
      .language__dropdown__list{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        background-color: #fff;
      padding: 10px 15px;
      height: 100%;


        .back_button{
          all: unset;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 10px;
          .icon{
            color: var(--snowflake);
          }
          p{
            font-size: 16px;
            font-weight: 800;
            font-family: var(--init-font);
            color: var(--snowflake);
            transition: all .3s ease-in-out;
            &:hover{
              text-decoration: underline;
            }
          }
        }
        .array__main{
          button{
            all: unset;
            border-bottom: 1px solid black;
            padding: 10px 0px !important;
            font-family: var(--init-font);
            font-weight: 800;
            font-size: 20px;
            width: 100%;
            display: flex;
            justify-content: space-between;

          }
          &:hover{
            button{
              color: var(--snowflake);
            }
          }
        }
      }
    }
  }
.swiper {
    width: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .multi__carousel{

    .swiper-wrapper{
        align-items: center;
        
        .swiper-slide{
            display: flex;
            align-items: center;
            img{
              max-width: 100%;
            }
        }
    }
  }

  
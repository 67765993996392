.innovation__dropdown__main {
  display: block;
}
.mobile__innovation__dropdown {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .innovation__dropdown__main {
    display: none;
  }
  .mobile__innovation__dropdown {
    display: block;
    .innovation__dropdown__list {
      background-color: #fff;
      padding: 15px;
      display: flex;
      flex-direction: column;
      top: 0;
      left: 0;
      right: 0;
      gap: 10px;
      transform: translateX(-100%);
      transition: all 0.3s ease-in-out;
      z-index: 1000;
      width: 100%;
      height: 100%;

      button{
        all: unset;
        cursor: pointer;
        color: #249EDC;
        display: flex;
        align-items: center;
        p{
            font-weight: 800;
            font-size: 20px;
            font-family: var(--init-font);
            color: var(--snowflake);

            &:hover{
                text-decoration: underline  ;
            }
            
        }
      }
      &.show {
        transform: translateX(0);
        position: absolute;
      }

      .innovation__dropdown__link{
        border-bottom: 1px solid black;
        padding: 20px 0px !important;
        transition: all .3s ;
        &:hover{
            color: var(--snowflake);
        }
      }
    }
  }
}

.hover__card__main {
    width: 45%;
    border: 1px solid rgba(0,0,0,.1);
    padding: 32px 15px;
    border-radius: 10px;
    height: 165px;
    overflow: hidden;
    transition: box-shadow .25s cubic-bezier(.4,0,.2,1);
    text-decoration: none;
    cursor: pointer;


    .icon__section {
        display: flex;
        justify-content: center;
        transition: all ease-in-out .3s;
        opacity: 1;
        transition: opacity ease-in-out .3s;

        .icon__container {
            width: 55px;
            height: 55px;
            border-radius: 100%;
            background-color: var(--snowflake);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .content__section {
        height: auto;
        max-height: 200px;
        overflow: hidden;
        transform: translateY(0);
        transition: all ease-in-out .3s;
        padding: 0 30px;

        h4{
            color: var(--blue);
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            margin-top: 15px;
        }
        p{
            color: var(--gray);
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            display: none;
        }
        button{
            background-color: transparent;
            padding: 0;
            border: none;
            outline: none;
            color: var(--snowflake);
            display: none;
        }
    }


    &:hover {
        box-shadow: 0 16px 16px 0 rgba(0,0,0,.16);
        .icon__section {
            opacity: 0;
        }

        .content__section {
            height: auto;
            max-height: 200px;
            transform: translateY(-80px);

            p{
                display:block;
            }
            button{
                display: inline;
            }
        }
    }
}

@media only screen and (max-width:525px){
    .hover__card__main{
        width: 100%;
    }
}
.strategies__main {
    width: 100%;
    padding: 80px 0;

    .header__section {
        h2 {
            font-size: 40px;
            font-weight: 500;
            text-align: center;

            span {
                font-weight: 900;
            }
        }
    }

    .cards__section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 30px;

        .card__custom {
            width: 33.33%;
            position: relative;
            padding-right: 3%;

            .count__ring {
                width: 62px;
                height: 62px;
                background-color: #6D83ED;
                box-shadow: 0 0 0 10px rgba(109, 131, 237, .14);
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                color: #fff;
                font-weight: 700;
            }

            h3 {
                font-size: 22px;
                font-weight: 700;
                color: var(--blue);
                margin-top: 25px;
            }

            .subtitle__container {
                background-color: #6D83ED;
                padding: 8px 14px;
                border-radius: 10px;
                margin-top: 15px;
                width: fit-content;

                p {
                    font-size: 18px;
                    font-weight: 700;
                    color: #fff;
                }
            }

            h5 {
                font-size: 18px;
                font-weight: 500;
                color: var(--blue);
                margin-top: 20px;
            }

            .objective__card {
                display: flex;
                gap: 15px;
                margin-top: 15px;
                font-size: 18px;
                font-weight: 400;

                .dot {
                    width: 10px;
                    height: 10px;
                    background-color: #6D83ED;
                    border-radius: 50%;
                    margin-top: 9px;
                }
            }

            &.second__card {
                .count__ring {
                    background-color: #B3D943;
                    box-shadow: 0 0 0 6px rgba(179, 217, 67, .14);
                }

                .subtitle__container {
                    background-color: #B3D943;

                    p {
                        color: var(--blue);
                    }
                }

                .objective__card {
                    .dot {
                        background-color: #B3D943;
                    }
                }
            }

            &::after {
                content: "";
                display: inline-block;
                position: absolute;
                width: 100%;
                height: 21px;
                pointer-events: none;
                top: 20px;
                left: 34px;
                z-index: -1;
                background-image: url("../../../assets/images/lines/hozizontalline.svg");
                background-repeat: no-repeat;
                background-size: cover;
            }

            &:last-of-type {
                &::after {
                    display: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .strategies__main {
        .cards__section {
            .card__custom {
                width: 100%;
                margin-top: 20px;
                display: flex;
                justify-content: space-between;

                .count__ring {
                    width: 40px;
                    height: 40px;
                }

                &::after {
                    display: none;
                }

                .content__section {
                    width: calc(100% - 60px);

                    ::after {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        width: 2px;
                        height: 100%;
                        pointer-events: none;
                        left: 20px;
                        background-image: url("../../../assets/images/lines/verticalline.svg");
                        background-repeat: no-repeat;
                        background-size: contain;
                        z-index: -1;
                    }

                    h3 {
                        margin-top: 0;
                    }

                    .objective__section {
                        &::after {
                            content: "";
                            display: inline-block;
                            position: absolute;
                            width: 16px;
                            height: 16px;
                            pointer-events: none;
                            background-image: url("../../../assets/images/lines/arrowDown.svg");
                            background-position: center;
                            background-repeat: no-repeat;
                            left: 13px;
                            top: 60%;
                            -webkit-transform: translateY(-50%);
                            transform: translateY(-50%);
                        }
                    }
                }

                &:last-of-type {
                    .content__section {

                        ::after {
                            background-image: none;
                            background-color: white;
                            top: 0;
                        }

                        .objective__section{
                            &::after{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
.footer__main {
    width: 100%;
    padding: 60px 0 30px;
    background-color: var(--blue);

    .footer__section {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;


        .intro__section {
            width: 33%;

            p {
                color: var(--gray);
                font-size: 17px;
                font-weight: 500;
                margin-top: 15px;
                margin-bottom: 15px !important;
            }

            img {
                max-width: 200px;
            }
        }

        .newsletter__container {
            width: 33%;
            display: flex;
            justify-content: flex-end;

            .newletter__section {
                width: 85%;

                h4 {
                    font-size: 25px;
                    font-weight: 600;
                    color: #fff;
                    margin-bottom: 20px !important;
                }

                h5 {
                    font-size: 18px;
                    font-weight: 600;
                    color: #fff;
                    margin-bottom: 20px !important;
                    text-decoration: underline;
                    margin-top: 60px;
                }

                .social__icons__section {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 20px;

                    .social__link {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 5px;
                        text-decoration: none;

                        p {
                            color: #fff;
                            font-size: 11px;
                            font-weight: 400;
                        }
                    }
                }

            }
        }

        .links__section__ {
            width: 33%;
        }
    }

    .links__section__container {
        display: flex;
        gap: 30px;
        justify-content: center;

        .links__section {

            h4 {
                font-size: 25px;
                font-weight: 600;
                color: #fff;
                border-bottom: 2px solid var(--green);
                padding-bottom: 4px;
            }

        }
    }



    .contact__list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 60px;
        padding-bottom: 35px;
        border-bottom: 1px solid var(--gray);
        flex-wrap: wrap;
        gap: 25px;

        .contact__list__item {
            display: flex;
            gap: 15px;
            // max-width: 33%;

            p {
                color: #2ea3f2;
                font-size: 14px;
                font-weight: 500;
                line-height: 1;
            }

            a {
                text-decoration: none;
                color: var(--gray);
                font-size: 18px;
                font-weight: 700;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: #2ea3f2;
                }
            }
        }
    }

    .copyright__section {
        padding: 30px 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        p {
            text-decoration: none;
            font-size: 15px;
            font-weight: 500;
            color: var(--gray);
        }

        a {
            text-decoration: none;
            font-size: 17px;
            font-weight: 700;
            color: var(--gray);
            transition: all 0.3s ease-in-out;

            &:hover {
                color: #2ea3f2;
            }
        }
    }

    .review__card {
        width: 40%;

        img {
            max-width: 100% !important;
        }
    }
}


@media only screen and (max-width:980px) {
    .footer__main {
        .footer__section {
            .intro__section {
                width: 35%;
            }

            .newsletter__container {
                width: 35%;
                display: flex;
                justify-content: flex-start;
                margin-top: 25px;

                .newletter__section {
                    width: 100%;

                    h5 {
                        margin-top: 40px;
                    }
                }
            }

            .links__section__ {
                width: 65%;
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .footer__main {
        .footer__section {
            .intro__section {
                width: 100%;
            }

            .newsletter__container {
                width: 50%;
            }

            .links__section__ {
                width: 100%;
                margin-top: 30px;

                .links__section__container {
                    .links__section {
                        width: 50%;
                        h4{
                            width: fit-content;
                            margin-bottom: 15px !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:525px) {
    .footer__main {
        .footer__section {
            .newsletter__container {
                width: 100%;
            }

        }
    }
}
.modernization__main {
    width: 100%;
    padding: 50px 0;
    position: relative;
    background-color: #F6F9FA;

    .header__section {
        h4 {
            font-size: 40px;
            font-weight: 800;
            color: var(--snowflake);
            text-align: center;
        }

        p {
            font-size: 20px;
            font-weight: 500;
            color: var(--gray);
            margin-top: 15px;
            text-align: center;
        }
    }

    .cards__section {
        display: flex;
        justify-content: space-between;
        margin-top: 35px;
        flex-wrap: wrap;
        row-gap: 25px;

        .empty__modr{
            width: calc(33% - 15px);
        }

        .modernization__card {
            width: calc(25% - 15px);
            background-color: #fff;
            border-radius: 20px;
            border-top: 8px solid var(--snowflake   );
            -webkit-box-shadow: 0 15px 25.6px 0 rgba(109, 131, 237, .25);
            box-shadow: 0 15px 25.6px 0 rgba(109, 131, 237, .25);
            -webkit-transition: .3s ease;
            transition: .3s ease;
            padding: 15px 15px 2.5rem;
            border-radius: 20px;

            .content__section {
                width: 100%;
                height: 100%;
                border-radius: 20px;
                color: var(--blue);
                transition: all ease-in-out 0.3s;
            }

            h4 {
                font-size: 18px;
                font-weight: 700;
                margin-top: 15px;
                text-transform: uppercase;
                
            }
            
            p {
                font-size: 15px;
                font-weight: 500;
                margin-top: 15px;
                line-height: 1.2;
                font-family: Poppins;
            }

            &:hover {
                background-color: var(--snowflake);
                border-color: #bec8fa;
                .content__section {
                    color: #fff;
                    opacity: 1;
                    
                    h4,p{
                    }
                }
            }
        }
    }

    .colored__div {
        width: 100%;
        height: 40%;
        // background-color: var(--snowflake);
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
        opacity: .8;
    }
}

@media only screen and (max-width:980px) {
    .modernization__main {

        .cards__section {

            .modernization__card {
                width: calc(33% - 15px);
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .modernization__main {

        .cards__section {

            .modernization__card {
                width: calc(50% - 15px);

                .content__section {
                    h4 {
                        font-size: 14px;
                    }

                    p {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:525px) {
    .modernization__main {

        .cards__section {

            .modernization__card {
                width: calc(100% - 8px);
            }
        }
    }
}
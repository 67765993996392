.success__story__carousel{
    .success__story__card{
        width: 100%;
        aspect-ratio: 1/1.1;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 10px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .text__section{
            padding: 20px;
            background-color: rgba($color: #000000, $alpha: 0.8);
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        p{
            color: #fff;
            font-size: 18px;
            max-width: 70%;
            line-height: 1.2;
        }
        button{
            color: var(--green);
            padding: 0;
            font-size: 13px;
            margin-top: 10px;
        }
    }

    .first__card{
        width: 100%;
        aspect-ratio: 1/1.1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        padding: 20px;

        h5{
            color: var(--gray);
            font-size: 13px;
            text-transform: uppercase;
            line-height: 1;
        }

        h3{
            font-size: 22px;
            font-weight: 700;
            margin-top: 10px;
        }

        p{
            margin-top: 20px;
            color: var(--gray);
            font-size: 14px;
            font-weight: 500;
        }
    }
}
.challenge__card__main{
    width: 28%;
    padding: 5px;
    border-left: 5px solid var(--green);
    padding-left: 25px;

    h5{
        font-size: 64px;
        font-weight: 500;
        line-height: 72px;
        color: var(--blue);
    }
    p{
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: rgb(38, 70, 112);
    }
    a{
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        color: rgb(109, 98, 236);
        text-decoration: none;
        margin-top: 5px;
    }
}

@media only screen and (max-width:980px){
    .challenge__card__main{
        width: 100%;
        margin-top: 25px;
        &:first-of-type{
            margin-top: 0;
        }
    }
}
.clients__section{
    width: 100%;
    padding: 100px 0;

    h5{
        font-size: 35px;
        line-height: 1.5;
        font-weight: 700;
        color: rgb(38, 70, 112);
        text-align: center;
    }

    .carousel__section{
        margin-top: 40px;
    }

    .btn__section{
        margin-top:60px ;
        text-align: center;
    }
}
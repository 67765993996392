.case__study__main {
    width: 100%;
    padding: 54px 0;
    background-color: #E8EDF2;

    .case__header {
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 2px solid #aab1b7;
        display: flex;
        justify-content: space-between;

        h4 {
            font-size: 28px;
            font-weight: 700;
            line-height: 24px;
        }

        .case__tabs {

            display: flex;
            gap: 10px;
            align-items: center;

            .case__change__btn {
                width: 35px;
                height: 13px;
                border: none;
                outline: none;
                background-color: #aab1b7;
                border-radius: 5px;

                &.active__btn {
                    background-color: var(--green);
                }
            }

            .view__all__btn {
                padding: 10px 15px;
                border: none;
                outline: none;
                border: 2px solid #4771d0;
                background-color: transparent;
                border-radius: 5px;
                color: #4771d0;
                font-size: 16px;
                font-weight: 700;
                line-height: 16px;
                height: 44px;

                &.active__btn{
                    background-color: #c7d5f2 ;
                }
            }
        }
    }

    .case__body {
        padding-top: 20px;
    }
}
.services__main{
    width: 100%;
    background-image: url("../../../assets/images/servicesbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    .service__sub__container{
        background-image: url("../../../assets/images/serviceman.jpg");
        background-position: right;
        background-repeat: no-repeat;
        background-size: auto 100%;
        padding: 50px 0;
        
        .service__flex__section{
    
            h4{
                font-size: 35px;
                font-weight: 700;
                line-height: 44px;
                color: white;
            }
        }
    }
}

@media only screen and (max-width:980px){
    .services__main{
    
        .service__sub__container{
            background-image: none;
        }
    }
}
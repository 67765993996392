@font-face {
  font-family: "Nunito";
  src: url("Nunito-Light.ttf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: "Nunito";
  src: url("Nunito-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("Nunito-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("Nunito-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("Nunito-Bold.ttf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("Nunito-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
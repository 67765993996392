@keyframes animate {
    0% {
        transform: perspective(500px) translate3d(0, 0, 0) rotateZ(-45deg);
        opacity: 1;
    }
    25% {
        transform: perspective(500px) translate3d(100px, 00px, 50px) rotateZ(0deg);
        opacity: 1;
    }
    35%, 100% {
        transform: perspective(500px) translate3d(150px, 0px, 100px) rotateZ(30deg);
        opacity: 0;
    }
}

.contact__us__modal__main {
    background: linear-gradient(to right, #5612a1, #e7969e);
    padding: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 10px;

    .contact__details__section {
        width: 50%;
        // padding-bottom: 80px;
        padding-right: 40px;

        h3 {
            color: #fff;
            font-weight: 700;
            font-size: 28px;
            line-height: 1.1;
        }

        p {
            color: #fff;
            font-size: 18px;
            margin-top: 10px;
            line-height: 1.1;
            opacity: .75;
            margin-top: 20px;
        }

        .links__section {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 20px;

            a {
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 10px;
                border: 1px solid #fff;
                border-radius: 10px;
                text-decoration: none;

                &:hover {
                    background-color: rgba(255, 255, 255, 0.2);
                }

                .icon__container {
                    width: 40px;
                    height: 40px;
                    border-radius: 8px;
                    background-color: rgba(255, 255, 255, 0.2);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    font-size: 25px;
                }

                p {
                    color: #fff;
                    font-weight: 700;
                    margin-top: 0;
                }
            }
        }

        .cards__container {
            position: absolute;
            left: 0;
            bottom: 0;

            .cards__c{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 50px;
                height: 70px;
                background-color: white;
                transform-style: preserve-3d;
                overflow: hidden;
                transform: rotateZ(-45deg);
                animation: animate 10s linear infinite;
                animation-delay: calc(0.3s* var(--i));
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    max-width: 90%;
                    max-height: 90%;
                    object-fit: cover;
                }
            }
        }
    }

    .form__section {
        width: 50%;
        background-color: #fff;
        margin-top: -55px;
        margin-bottom: -55px;
        border-radius: 10px;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 20px;

        h3 {
            text-align: center;
            font-weight: 800;
            margin-bottom: 10px !important;
        }
    }

    .close__btn{
        border: none;
        outline: none;
        background-color: #e08686;
        color: #fff;
        border-radius: 100%;
        position: absolute;
        right: -15px;
        top: -13px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
            opacity: .7;
        }
    }
}

.modal-content{
    border-radius: 15px !important;
}
@media only screen and (max-width:1200px){
    .contact__us__modal__main{
        .form__section{
            width: 50%;
        }
    }
}

@media (max-width:992px){
    .modal-dialog{
        max-width: 745px;
    }
}

@media only screen and (max-width:768px){
    .contact__us__modal__main{
        .contact__details__section{
            width: 100%;
            padding-right: 0;
            padding-bottom: 20px;

            .cards__container{
                display: none;
            }
        }
        .form__section{
            width: 100%;
            margin: 0;
        }
        .close__btn{
            top: 10px;
            right: 10px;
        }
    }
}
.innovation__hero__main {
    width: 100%;
    padding: 140px 0 0;
    background-color: #F6F9FA;
    // background-image: url("../../../assets/images/innovationImg.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    .flex__section {
        display: flex;
        align-items: center;

        .text__section {
            width: 50%;

            h4 {
                font-size: 18px;
                font-weight: 300;
                text-transform: uppercase;
                letter-spacing: 3px;
            }

            h3 {
                font-weight: 700;
                color: var(--blue);
                font-size: 63px;
                margin-top: 5px;
            }

            p {
                font-size: 24px;
                font-weight: 400;
                color: var(--gray);
                margin-top: 40px;
                padding-right: 50px;
            }
        }

        .img__section{
            width: 50%;
            img{
                max-width: 100%;
            }
        }
    }

    .bottom__lines__container{
        width: 100%;
        display: flex;
        margin-top: 140px;
        div{
            width: 50%;
            height: 5px;
            background-color: var(--snowflake);

            &.orange__line{
                background-color: var(--green);
            }
        }
    }

}

@media only screen and (max-width:768px) {
    .innovation__hero__main {
        padding: 60px 0 0;
    }
}

@media only screen and (max-width:525px) {
    .innovation__hero__main {
        padding: 40px 0 0;
        .flex__section{
            .text__section{
                h4 {
                    font-size: 14px;
                }
    
                h3 {
                    font-size: 32px;
                }
    
                p {
                    font-size: 19px;
                    margin-top: 25px;
                }
            }
        }
        .bottom__lines__container{
            margin-top: 100px;
        }
    }
}
.numbers__section__main {
    width: 100%;
    padding: 65px 0;
    background-color: #E8EDF2;

    h4 {
        font-size: 28px;
        line-height: 1;
        font-weight: 700;
        color: rgb(38, 70, 112);
    }

    .cards__section {
        margin-top: 35px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .number__card {
            width: 25%;
            padding: 5px;
            padding-left: 25px;
            border-left: 6px solid var(--green);
            color: rgb(53, 72, 110);

            h3 {
                font-size: 50px;
                line-height: 52px;
                font-weight: 500;
            }

            p {
                font-size: 20px;
                line-height: 24px;
                font-weight: 500;
            }
        }
    }
}

@media only screen and (max-width:980px) {
    .numbers__section__main {
        .cards__section {
            .number__card {
                width: 100%;
                padding-top: 20px;
            }
        }
    }
}
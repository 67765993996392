.full__width__carousel {
    width: 100%;
    .swiper-slide{
        background-color: transparent !important;
    }

    .carousel__card {
        text-align: left;
        // padding: 30px 0;
        .content__section {
            width: 60%;
            color: #fff;

            &.text__blue{
                color: var(--blue);
            }

            h4{
                font-size: 60px;
                font-weight: 700;
                line-height: 1;
            }

            p{
                font-size: 28px;
                font-weight: 500;
                max-width: 70%;
                margin-top: 15px;
            }
        }

        .image__section {
            width: 40%;

            img{
                max-width: 80%;
            }
        }
    }
}

@media only screen and (max-width:768px){
    .full__width__carousel{
        .carousel__card{
            .content__section{
                width: 100%;
                text-align: center;
                h4{
                    font-size: 40px;
                }

                p{
                    font-size: 20px;
                    max-width: 100%;
                }
            }

            .image__section{
                display: none;
            }
        }
    }
}
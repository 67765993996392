.primary__btn__main {
    border: none;
    outline: none;
    padding: 12px 25px;
    background-color: var(--green);
    font-size: 16px;
    font-weight: 700;
    color: var(--blue);
    border-radius: 6px;
    transition: color 300ms ease 0ms, background-color 300ms ease 0ms, padding 300ms ease 0ms;

    &:hover {
        padding: 12px 30px;
    }

    &.blue__bg {
        background-color: #4771D0;
        color: #fff;
    }

    &.snowflake__btn {
        background-color: transparent;
        border: 1px solid #fff;
        border-radius: 50px;
        color: #fff;

        &.colored{
            border: 1px solid var(--snowflake);
            color: var(--snowflake);
        }
    }
}
.innovation__accelerator{
    width: 100%;
    padding: 100px 0;
    background: linear-gradient(180deg,rgba(179,217,67,0) 0,rgba(179,217,67,.1) 100%);

    .content__section{
        h4{
            color: var(--snowflake);
            font-size: 40px;
            font-weight: 400;
        }

        h3{
            font-weight: 800;
            font-size: 50px;
            color: var(--blue);
        }

        p{
            margin-top: 25px;
            font-size: 24px;
            font-weight: 400;
            max-width: 800px;
        }
    }
    
    .tabs__section{
        display: flex;
        justify-content: space-between;
        margin-top: 40px;

        button{
            border: none;
            outline: none;
            padding: 12px 30px;
            width: 30%;
            box-shadow: 0px 8px 16px 0px rgba(51, 55, 45, 0.16);
            border-radius: 70px;
            font-size: 20px;
            font-weight: 700;
            color: var(--blue);
            background-color: #fff;

            &.active__btn{
                background-color: #6C81EA;
                color: #fff;
            }
        }
    }

    .tab__detail__section{
        margin-top: 35px;
        h3{
            font-size: 30px;
            color: var(--blue);
            font-weight: 700;
        }
        .flex__section{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            
            .text__section{
                width: 48%;
                padding-right: 70px;
                
                h5{
                    font-size: 22px;
                    font-weight: 700;
                    color: var(--blue);
                    margin-top: 20px;
                }
                p{
                    font-size: 20px;
                    font-weight: 500;
                    color: var(--gray);
                    margin-top: 20px;
                }
            }
            
            .img__section{
                width: 52%;
                text-align: right;

                img{
                    width: 100%;
                    border-radius: 10px;
                    height: 510px;
                    object-fit: cover;
                }
            }

            &.reverse__row{
                flex-direction: row-reverse;
                .text__section{
                    padding-right: 0;
                    padding-left: 50px;
                }
            }
        }
    }
}

@media only screen and (max-width:1024px){
    .innovation__accelerator{
        .tab__detail__section{
            .flex__section{
                .text__section{
                    width: 100%;
                    padding-right: 0;
                }
                .img__section{
                    width: 100%;
                    margin-top: 30px;
                }

                &.reverse__row{
                    flex-direction: row;
                    .text__section{
                        padding-left: 0;
                    }
                }
            }
        }
    }
}
.article__section__main {
    width: 100%;
    padding: 80px 0;

    .flex__section {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .article__card__main {
            display: flex;
            justify-content: space-between;
            padding: 50px 0;
            &:nth-last-of-type(even){
                background: #e8fffc;
                background: linear-gradient(to bottom, #e8fffc 0, #fff4f4 100%);
            }
            .custom__container{
                display: flex;
            justify-content: space-between;
            }

            img {
                max-width: 100%;
            }

            ul {
                &.number {
                    list-style: decimal;
                }
            }

            .text__section {
                padding-right: 80px;

                h2 {
                    color: var(--blue-white);
                    font-size: 36px;
                    font-weight: 800;
                }

                p {
                    margin-top: 25px;
                    color: rgb(106, 106, 142);
                }

                ul {
                    margin-top: 25px;

                    li {
                        font-size: 17px;
                        color: rgb(106, 106, 142);

                        span {
                            font-weight: 700;
                        }

                        h3{
                            margin-top: 25px;
                            color: black;
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
}
.hero__main__section {
  width: 100%;
  background-color: #004360;
  position: relative;
  margin-bottom: 100px;

  .curve__image__bg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-image: url("../../../assets/svg/heroBottom.svg");
    background-size: 100% 100px;
    bottom: 0;
    height: 100px;
    z-index: 2;
    transform: scale(1, 1);
  }

  .video__container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
  }

  .hero__flex__section {
    width: 100%;
    display: flex;
    z-index: 2;
    position: relative;
    padding-top: 74px;
    justify-content: space-between;

    .content__section {
      width: 50%;
      padding-top: 55px;
      color: #fff;
      padding-bottom: 164px;

      h1 {
        font-weight: 900;
        font-size: 64px;
        line-height: 1;
        font-family: var(--init-font);
      }

      p {
        font-weight: 800;
        font-size: 22px;
        line-height: 26px;
        margin-top: 30px;
        font: var(--init-font);
      }

      .btn__container {
        margin-top: 30px;
      }

      .action__btns__section {
        margin-top: 30px;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }

    .image__section {
      width: 50%;
      overflow: hidden;
      text-align: right;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: relative;

      .down__image {
        width: 90%;
        height: 100%;
        position: relative;
        z-index: -1;
        img {
          width: 100%;
          height: auto;
        }
      }

      .upper__image {
        max-width: 90%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: right center;
        }
      }
    }
  }
  .absolute__section {
    position: absolute;
    top: 90%;
    padding: 10px 0px;
    box-sizing: border-box;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    .center__absoulte__main {
      width: 90%;
      background-color: #fff;
      box-shadow: 0 5px 15px rgba(0, 61, 121, 0.1);
      border-radius: 10px;
    //   height: 100%;
      height: 150px;

      .left__absoulte {
        width: 25%;
        height: 100%;
        margin: 0;
        padding: 0;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left center;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            
        }
      }

      .center__absoulte {
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
        h5{
            font-weight: 800;
            font-size: 22px;
            font-family: var(--init-font);
        }
        p{
            font-size: 14px;
            font-weight: 400;
            font-family: var(--primary-font);
        }
      }

      .right__absoulte {
      }
    }
  }
}

@media only screen and (max-width:992px) {

    .hero__main__section{
        margin-bottom: 400px;
        .hero__flex__section{
          flex-direction: column;
          align-items: center;
          .content__section{
            width: 100% !important;
          }
          .image__section{
            width: 100% !important;
            height: 250px;
            
            .upper__image{
              max-width: none;
                width: 100% !important;
                img{
                    object-position: right center;
                }
            }
            .down__image{
                width: 100% !important;
            }
          }
        }
        .absolute__section{
                .center__absoulte__main{
                    height: auto;
                    padding-bottom: 20px;
                    .left__absoulte{
                        width: 100%;
                        height: 250px;
                        img{
                            border-bottom-left-radius: 0px;
                            border-top-right-radius: 10px;
                        }
                    }
                    .center__absoulte{
                        padding: 20px 30px;
                    }
                    .right__absoulte{
                        padding: 0px 30px;

                        .w__lg__100{
                            width: 100%;
                        }
                    }
                }
        }
    }
    
}

@media only screen and (max-width: 1300px) {
  .hero__main__section {
    .hero__flex__section {
      .content__section {
        width: 58%;
      }

      .image__section {
        width: 42%;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .hero__main__section {
    .hero__flex__section {
      .content__section {
        width: 100%;
      }

    }
  }
}

@media only screen and (max-width: 768px) {
  .hero__main__section {
    .curve__image__bg {
      height: 40px;
      background-size: 100% 40px;
    }
  }
}

@font-face {
  font-family: "Texta";
  src: url("TextaBlack.ttf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: "Texta";
  src: url("TextaRegular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("TextaRegular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("TextaMedium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("TextaBold.ttf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src: url("TextaBold.ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
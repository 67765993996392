.dbt__hero__main {
    width: 100%;
    padding: 80px 0;
    background-color: #F6F9FA;

    .flex__section {
        display: flex;
        align-items: center;

        .text__section {
            width: 50%;

            h3{
                font-weight: 800;
                color: var(--blue);
                font-size: 45px;
            }

            p{
                font-size: 20px;
                color: var(--gray);
                margin-top: 20px;
                padding-right: 50px;
            }
        }

        .img__section {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            img{
                max-width: 100%;
                object-fit: cover;
            }
        }
    }

    .about__section{
        margin-top: 25px;
        h4{
            color: var(--blue);
            font-size: 24px;
            font-weight: 700;
        }
        p{
            font-size: 16px;
            color: var(--gray);
            margin-top: 20px;
        }
    }
}

@media only screen and (max-width:768px){
    .dbt__hero__main{
        .flex__section{
            flex-direction: column;

            .text__section{
                width: 100%;
                padding-right: 0;
                text-align: center;
                p{
                    padding: 0;
                }
            }

            .img__section{
                width: 100%;
                justify-content: center;
                margin-top: 20px;
            }
        }
    }
}
@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Light.ttf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Bold.ttf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
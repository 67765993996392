.dbt__offers__main {
    padding: 80px 0;

    .offer__heading {
        font-size: 48px;
        font-weight: 700;
        color: var(--blue);
    }

    .cards__section {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-top: 50px;

        .offer__card {
            padding: 20px 35px;
            box-shadow: 0 5px 15px rgba(0, 61, 121, 0.1);
            border-radius: 10px;
            cursor: pointer;

            img {
                max-width: 79px;
            }

            h5 {
                font-weight: 700;
                color: var(--blue);
                font-size: 24px;
            }

            p {
                font-size: 19px;
                color: var(--gray);
                margin-top: 10px;
            }

            &:hover {
                box-shadow: 0 0px 30px rgba(0, 61, 121, 0.2) !important;
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .dbt__offers__main {
        padding: 0 0 60px;
    }
}

@media only screen and (max-width:525px) {
    .dbt__offers__main {
        .cards__section {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
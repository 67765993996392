.testimonial__main {
    width: 100%;
    padding: 60px 0;
    background-color: #fff;

    h4 {
        font-size: 24px;
        font-weight: 500;
        color: var(--gray);
        text-align: center;
    }

    h5 {
        font-size: 40px;
        font-weight: 700;
        color: var(--blue);
        text-align: center;
        max-width: 600px;
        margin: auto;
        margin-top: 20px;
    }

    .testimonial__flex__section {
        width: 100%;
        display: flex;
        margin-top: 30px;
        flex-wrap: wrap;

        .video__container {
            width: 50%;
            padding-right: 20px;

            .video__thumbnail {
                width: 45%;
                aspect-ratio: 109/85;
                background-size: cover;
                background-repeat: no-repeat;
                border: none;
                outline: none;
                border-radius: 10px;
                padding: 0;
                display: flex;
                align-items: flex-end;

                .flex__box {
                    background: -webkit-linear-gradient(top, rgb(30, 87, 153, 0) 0, rgb(32, 124, 202, 0) 70%, rgb(0, 0, 0, .5) 100%);
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    padding: 10px;
                    width: 100%;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }

                p {
                    color: #fff;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 1;
                }

                .play__btn__container {
                    background-color: var(--blue);
                    border-radius: 100%;
                    width: 22px;
                    height: 22px;
                    display: flex;
                }
            }
        }

        .carousel__container {
            width: 100%;

        }
    }
}

@media only screen and (max-width:980px){
    .testimonial__main{
        .testimonial__flex__section{
            .carousel__container,.video__container{
                width: 100%;
            }
            .carousel__container{
                margin-top: 25px;
            }
        }
    }
}
.consulting__section__main {
    padding: 50px 0;
    background-color: #E8EDF2;

    .consulting__section__heading {
        font-weight: 700;
        font-size: 35px;
        line-height: 44px;
        color: var(--blue);
    }

    .consulting__flex__section{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-left: 55px;
        padding-top: 30px;
        
        .text__section{

            width: 55%;

            h5{
                font-size: 28px;
                line-height: 34px;
                font-weight: 800;
                color: var(--blue);
            }

            p{
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: #000;
                margin-top: 20px;

                &.second__description{
                    margin-top: 12px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    color: rgb(51, 51, 51);
                }
            }

            .btn__container{
                width: 100%;
                display: flex;
                gap: 15px;
                margin-top: 30px;
            }
        }

        .image__section{
            // width: 45%;
            padding-left: 30px;

            img{
                width: auto;
                height: 365px;
                border-radius: 10px;
                margin-top: -48px;
            }
        }
    }
}

@media only screen and (max-width:980px){
    .consulting__section__main{
        .consulting__flex__section{
            padding-left: 0;
            .text__section{
                width: 100%;
            }

            .image__section{
                width: 100%;
                padding-left: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                img{
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
.animate__btn__main{
    background: linear-gradient(to right, #55d1c0 50%, rgba(14, 70, 139, 0.81) 50%) !important;
    background-size: 200% 100% !important;
    background-position: right bottom !important;
    transition: all .5s ease-out !important;
    font-weight: 700;
    font-size: 16px;
    border-left: 10px solid var(--green);
    padding: 20px 40px 20px 20px;
    display: inline-block;
    cursor: pointer;
    width: fit-content;

    &:hover{
        background-position: left bottom !important;
        color: rgb(24, 42, 59);
    }
}
.blog__card__main {
    position: relative;
    border: none;
    outline: none;
    padding: 0;
    background-color: transparent;
    height: 400px;



    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .content__section {
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.3);
        position: relative;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 5px;
        text-align: left;
        padding: 25px;

        h5 {
            font-size: 16px;
            text-transform: uppercase;
            color: #fff;
            font-weight: 300;
            cursor: pointer;
            &:hover{
                color: var(--green);
            }
        }

        p {
            font-size: 22px;
            font-weight: 600;
            color: #fff;
            line-height: 1.2;
            cursor: pointer;
            &:hover{
                color: var(--green);
            }
        }

        button {
            max-height: 0;
            border: none;
            outline: none;
            padding: 0;
            background-color: transparent;
            width: fit-content;
            font-size: 18px;
            font-weight: 700;
            color: var(--green);
            transition: all ease-in-out 0.3s;
            overflow: hidden;
        }

        &:hover {
            button {
                max-height: 80px;


            }
        }
    }
}

@media only screen and (max-width:768px){
    .blog__card__main{
        .content__section{
            button{
                max-height: 80px;
            }
        }
    }
}
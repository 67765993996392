.blog__section__main{
    width: 100%;
    padding: 80px 0;

    .blogs__section{

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;


    }
}

@media only screen and (max-width:920px){
    .blog__section__main{
    
        .blogs__section{
    
            grid-template-columns: repeat(2, 1fr);   
    
        }
    }
}

@media only screen and (max-width:525px){
    .blog__section__main{
    
        .blogs__section{
    
            grid-template-columns: repeat(1, 1fr);   
    
        }
    }
}
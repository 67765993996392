.competenties__main {
    width: 100%;
    padding: 50px 0;
    position: relative;

    .header__section {
        h4 {
            font-size: 40px;
            font-weight: 800;
            color: var(--blue);
            text-align: center;
        }

        p {
            font-size: 20px;
            font-weight: 500;
            color: var(--gray);
            margin-top: 15px;
            text-align: center;
        }
    }
}
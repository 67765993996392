.newsletter__input{
    width: 100%;
    background-color: black;
    padding: 14px;
    border-radius: 10px;
    display: flex;
    align-items: center;

    input{
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        color: #fff;
        
        &::placeholder{
            color: var(--gray);
        }
    }

    button{
        border: none;
        outline: none;
        background-color: var(--green);
        border-radius: 100%;
        width: 30px;
        height: 30px;
    }
}
.case__card__main {
    display: flex;

    &.border_card{
        border: 1px solid rgb(208 219 233 / 70%);
        padding: 20px 0;
        margin-top: 20px;
        border-radius: 6px;
    }

    .company__data {
        width: 25%;

        .company__data__card {
            width: 95%;
            background-color: #fff;
            border-radius: 5px;
            padding: 40px 30px;

            img{
                max-width: 100%;
            }
            
            .second__logo{
                margin-top: 15px;
            }

            p {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: rgb(88, 88, 96);
                margin-top: 20px;
            }
        }
    }

    .challenges {
        width: 75%;
        padding-left: 30px;
        padding-right: 20px;

        h5,
        p {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #000;
        }

        h5 {
            font-weight: 700;
            margin-top: 12px;

            &:first-of-type {
                margin-top: 0;
            }
        }
    }
}
.service__card__main{
    padding-left: 30px;
    border-left: 4.5px solid #5382e0;
    height: auto;
    max-height: 26px;
    margin-top: 25px;
    color: #fff;
    max-width: 400px;
    transition: 500ms ease-in-out 0ms;
    overflow: hidden;

    h5{
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
    }
    p{
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        margin-top: 5px;
    }
    

    &:hover{
        border-left: 4.5px solid var(--green);
        max-height: 200px
    }
}
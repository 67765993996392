.challenges__section__main{
    width: 100%;
    padding: 40px 0;

    .tabs__section{
        display: flex;
        gap: 30px;

        .tab__btn{
            border: none;
            outline: none;
            background-color: transparent;
            padding: 10px 0;
            font-size: 35px;
            font-weight: 700;
            line-height: 24px;
            color: #8b9399;
            border-bottom: 5px solid #8b9399;
            
            &.active__tab{
                border-bottom: 5px solid var(--green);
                color: var(--blue);
            }
            &:hover{
                color: var(--blue);
            }
        }

    }
    .tabs__info__section{
        margin-top: 30px;
        
        .description{
            font-size: 24px;
            font-weight: 500;
            color: var(--gray);
            max-width: 850px;
        }
        .list__section{
            width: 100%;
            display: flex;
            justify-content: space-around;
            margin-top: 50px;
            flex-wrap: wrap;
        }
    }
}

@media only screen and (max-width:768px){
.challenges__section__main{
    .tabs__section{
        .tab__btn{
            font-size: 20px;
        }
    }
    .tabs__info__section{
        .description{
            font-size: 18px;
        }
    }
}
}
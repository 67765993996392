.video__modal__container {
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;

    .close__btn{
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0;
        position: absolute;
        right: 40px;
        top: 40px;
    }
}
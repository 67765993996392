.about__us__main {
    padding-top: 80px;

    .text__section {
        .heading__text {
            width: 45%;
            padding-right: 30px;

            h3 {
                font-size: 40px;
                line-height: 48px;
                font-weight: 700;
                color: var(--blue);
            }

            p {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: var(--gray);
                margin-top: 15px;
            }
        }

        .card__section {
            width: 55%;
            padding: 40px;
            background-color: #E8EDF2;

            h4 {
                font-size: 25px;
                line-height: 30px;
                font-weight: 500;
                color: var(--blue);
            }

            .connect__btn {
                border: none;
                outline: none;
                background-color: transparent;
                color: rgb(109, 98, 236);
                font-size: 17px;
                font-weight: 24px;
                font-weight: 700;
            }
        }
    }

    .cards__section {
        display: flex;
        justify-content: space-between;
        margin-top: 70px;
        padding-bottom: 100px;
        flex-wrap: wrap;

        .about__us__card {
            width: 25%;
            border-left: 1px solid var(--green);
            color: #000;
            padding: 0 20px;


            h4 {
                font-size: 18px;
                font-weight: 700;
                line-height: 24px;
            }

            p {
                font-size: 16px;
                font-weight: 500;
                margin-top: 20px;
                a {
                    color: rgb(46, 163, 242);
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;
                    text-decoration: none;
                }
            }

            img{
                max-width: 70px;
            }

            &.is_aws{
                img{
                    max-width: 120px;
                    margin-bottom: -34px;
                    margin-left: -32px;
                    margin-top: -10px;
                }
            }
        }
    }
}

@media only screen and (max-width: 980px) {
    .about__us__main {
        .text__section {
            .heading__text {
                width: 100%;
            }

            .card__section {
                width: 100%;
                margin-top: 20px;
            }
        }

        .cards__section {
            .about__us__card {
                width: 50%;
            }
        }
    }
}

@media only screen and (max-width: 525px) {
    .about__us__main {
        .cards__section {
            .about__us__card {
                width: 100%;
            }
        }
    }
}
.navbar__main {
  width: 100%;
  height: 85px;
  background-color: #fff;

  &.snowflakeBg {
    background-color: var(--snowflake);
  }

  .navbar__flex__section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .left__section {
      display: flex;
      align-items: center;
      gap: 44px;
      width: 25%;

      .logo__section {
        img {
          max-width: 244px;
          max-height: 68px;
        }
      }

      .nav__tabs__section {
        display: flex;
        gap: 22px;
        justify-content: center;
        align-items: center;

        .dropdown-toggle {
          border: none;
          outline: none;
          background-color: transparent;
          color: #000;
          font-weight: 800;
          font-size: 17px;
          padding: 0;
          font-family: var(--init-font);

          &::after {
            display: none;
          }
        }
        button {
          color: #000;
        }
        .dropdown-item {
          background-color: transparent;
          a {
            color: #000;
            text-decoration: none;
          }
        }
      }
    }

    .right__section {
      display: flex;
      align-items: center;
      gap: 12px;
      .dropdown__main {
        .dropdown-toggle {
          &::after{
            display: none;
          }
        }
      }
    }
  }

  &.isAbsolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    background-color: transparent;
  }

  .mob__nav__main {
    width: 100%;
    position: relative;

    .menu__and__logo {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        max-width: 180px;
      }

      .toggle__menu__btn {
        padding: 0;
        border: none;
        outline: none;
        background-color: transparent;
      }
    }

    .open__menu {
      background-color: #fff;
      padding: 15px;
      display: flex;
      flex-direction: column;
      top: 78%;
      left: 0;
      right: 0;
      gap: 10px;
      transform: translateX(-120%);
      transition: all 0.3s ease-in-out;
      z-index: 1000;
      width: 100%;
      height: 89vh;

      .cursor__pointer {
        cursor: pointer;
      }

      button,
      a {
        font-size: 18px;
        font-weight: 500;
        color: var(--blue);
        text-decoration: none;
        padding: 0;
        font-family: var(--init-font);
        font-size: 20px;
        font-weight: 800;
      }

      &.actie__menu {
        transform: translateX(0);
      position: absolute;

      }
      
    }
  }
}
